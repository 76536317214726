import debounce from 'debounce';

document.addEventListener('DOMContentLoaded', () => {
  const navOpenElem = document.querySelector('#nav-open');
  const navCloseElem = document.querySelector('#nav-close');
  const mainNavigation = document.querySelector('.navigation-link-wrapper');
  const navBackground = document.querySelector('.sub-navigation__background');

  const subNavigationToggle = document.querySelectorAll(
    '.sub-navigation-toggle',
  );

  let screenSize =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  function toggleNavigation(isOpen) {
    if (isOpen) {
      // Open main nav
      navOpenElem.classList.add('burger-icon--hidden');
      navCloseElem.classList.remove('burger-icon--hidden');
      mainNavigation.classList.remove('navigation-link-wrapper--hidden');
      navBackground.classList.remove('sub-navigation__background--hidden');
    } else {
      // Close main nav
      navOpenElem.classList.remove('burger-icon--hidden');
      navCloseElem.classList.add('burger-icon--hidden');
      mainNavigation.classList.add('navigation-link-wrapper--hidden');
      navBackground.classList.add('sub-navigation__background--hidden');
    }
  }

  function toggleSubNavigation(isOpen, toggle, subNav) {
    const subNavigationAccordion = toggle.querySelector('.item-link__icon');
    const hasDropdownList = subNav.classList.contains(
      'sub-navigation--dropdown-list',
    );

    subNavigationToggle.forEach((element) => {
      const elementSubNav = element.querySelector('.sub-navigation');
      const elementIsOpen =
        elementSubNav.getAttribute('data-is-open') === 'true';
      const elementSubNavTitle = elementSubNav.querySelector(
        '.sub-navigation__header',
      ).innerText;
      const subNavTitle = subNav.querySelector(
        '.sub-navigation__header',
      ).innerText;

      // if any other menus are open when a different sub nav toggle is clicked, close them
      if (elementIsOpen && elementSubNavTitle !== subNavTitle) {
        toggleSubNavigation(true, element, elementSubNav);
      }
    });

    if (isOpen) {
      subNav.classList.add('sub-navigation--hidden');
      subNav.setAttribute('data-is-open', 'false');
      if (screenSize >= 768) {
        subNavigationAccordion.classList.remove('item-link__icon--reversed');
      }
      if (!hasDropdownList) {
        navBackground.classList.add('sub-navigation__background--hidden');
      }
    } else {
      subNav.classList.remove('sub-navigation--hidden');
      subNav.setAttribute('data-is-open', 'true');
      if (screenSize >= 768) {
        subNavigationAccordion.classList.add('item-link__icon--reversed');
      }
      if (!hasDropdownList) {
        navBackground.classList.remove('sub-navigation__background--hidden');
      }
    }
  }

  function hideAllNavigationItems() {
    for (let i = 0; i < subNavigationToggle.length; i++) {
      const subNavToggle = subNavigationToggle[i];
      const subNav = subNavigationToggle[i].querySelector('.sub-navigation');
      toggleSubNavigation(true, subNavToggle, subNav);
    }
  }

  function resize() {
    screenSize =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    if (screenSize >= 768) {
      toggleNavigation(false);
      hideAllNavigationItems();
    }
  }

  window.onresize = debounce(resize, 200);

  navOpenElem.addEventListener('click', () => {
    toggleNavigation(true);
  });

  navCloseElem.addEventListener('click', () => {
    toggleNavigation(false);
  });

  // Init sub navigation logic.
  for (let i = 0; i < subNavigationToggle.length; i++) {
    const subNavToggle = subNavigationToggle[i];
    const subNav = subNavigationToggle[i].querySelector('.sub-navigation');

    subNavToggle.addEventListener('pointerenter', () => {
      if (screenSize >= 1025) {
        const isOpen = subNav.getAttribute('data-is-open') === 'true';

        if (isOpen) {
          return;
        }
        toggleSubNavigation(isOpen, subNavToggle, subNav);
      }
    });

    subNavToggle.addEventListener('pointerleave', () => {
      if (screenSize >= 1025) {
        const isOpen = subNav.getAttribute('data-is-open') === 'true';
        toggleSubNavigation(isOpen, subNavToggle, subNav);
      }
    });

    // Handle keyboard 'enter' to open sub nav
    subNavToggle.addEventListener('keydown', (e) => {
      if (e.keyCode === 13) {
        const isOpen = subNav.getAttribute('data-is-open') === 'true';
        toggleSubNavigation(isOpen, subNavToggle, subNav);
      }
    });

    subNavToggle.addEventListener('click', (e) => {
      if (screenSize >= 1025) {
        // on desktop we are using a hover state. On click want to link to categories page
        return;
      }
      if (
        e.target.classList.contains('nav-link-list__item-link') ||
        e.target.classList.contains('item-link__icon')
      ) {
        e.preventDefault();
      }

      const isOpen = subNav.getAttribute('data-is-open') === 'true';

      toggleSubNavigation(isOpen, subNavToggle, subNav);
    });
  }
});
